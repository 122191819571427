define('tal-calculator/components/tal-calc-debug', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    progress: Ember.inject.service('tal-calc-progress'),
    calculations: Ember.inject.service('tal-calc-calculations'),
    questions: null
  });
});