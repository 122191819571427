define('ember-islands/components/ember-islands', ['exports', 'ember'], function (exports, _ember) {
  var $ = _ember['default'].$;
  var Component = _ember['default'].Component;
  var getOwner = _ember['default'].getOwner;
  var Logger = _ember['default'].Logger;
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.renderComponent = getRenderComponentFor(this);
      this.componentsToRender = queryIslandComponents();
      this.renderedComponents = [];
    },

    didInsertElement: function didInsertElement() {
      this.renderedComponents = this.componentsToRender.map(this.renderComponent);
    },

    willDestroyElement: function willDestroyElement() {
      this.renderedComponents.forEach(function (renderedComponent) {
        renderedComponent.destroy();
      });
    }
  });

  function componentAttributes(element) {
    var attrs = undefined;
    var attrsJSON = element.getAttribute('data-attrs');

    if (attrsJSON) {
      attrs = JSON.parse(attrsJSON);
    } else {
      attrs = {};
    }

    attrs.innerContent = element.innerHTML;
    return attrs;
  }

  function getRenderComponentFor(emberObject) {
    var owner = getOwner(emberObject);

    return function renderComponent(_ref) {
      var name = _ref.name;
      var attrs = _ref.attrs;
      var element = _ref.element;

      var _lookupComponent = lookupComponent(owner, name);

      var component = _lookupComponent.component;
      var layout = _lookupComponent.layout;

      _ember['default'].assert(missingComponentMessage(name), component);

      // This can only be true in production mode where assert is a no-op.
      if (!component) {
        var _provideMissingComponentInProductionMode = provideMissingComponentInProductionMode(owner, name);

        component = _provideMissingComponentInProductionMode.component;
        layout = _provideMissingComponentInProductionMode.layout;
      }

      if (layout) {
        attrs.layout = layout;
      }

      $(element).empty();
      var componentInstance = component.create(attrs);
      componentInstance.appendTo(element);
      return componentInstance;
    };
  }

  function queryIslandComponents() {
    var components = [];

    $('[data-component]').each(function () {
      var name = this.getAttribute('data-component');
      var attrs = componentAttributes(this);
      components.push({ attrs: attrs, name: name, element: this });
    });

    return components;
  }

  function lookupComponent(owner, name) {
    var componentLookupKey = 'component:' + name;
    var layoutLookupKey = 'template:components/' + name;
    var layout = owner._lookupFactory(layoutLookupKey);
    var component = owner._lookupFactory(componentLookupKey);

    if (layout && !component) {
      owner.register(componentLookupKey, Component);
      component = owner._lookupFactory(componentLookupKey);
    }

    return { component: component, layout: layout };
  }

  function missingComponentMessage(name) {
    return 'ember-islands could not find a component named "' + name + '" in your Ember application.';
  }

  function provideMissingComponentInProductionMode(owner, name) {
    Logger.error(missingComponentMessage(name));

    return lookupComponent(owner, 'ember-islands/missing-component');
  }
});