define("tal-calculator/data/default", ["exports"], function (exports) {
    /* global exports */
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = {
        "config": {
            "providerName": "TAL",
            "coverageLimit": 9999999
        },
        "questions": [{
            "title": "What's your income?",
            "key": "incomeAmount",
            "answers": [{
                "label": "$0 - $40<span>K</span>",
                "value": 40000,
                "icon": "tal-calculator/assets/icons/income-0.svg"
            }, {
                "label": "$41<span>K</span> - $60<span>K</span>",
                "value": 60000,
                "icon": "tal-calculator/assets/icons/income-40.svg"
            }, {
                "label": "$61<span>K</span> - $80<span>K</span>",
                "value": 80000,
                "icon": "tal-calculator/assets/icons/income-60.svg"
            }, {
                "label": "$81<span>K</span> - $100<span>K</span>",
                "value": 100000,
                "icon": "tal-calculator/assets/icons/income-80.svg"
            }, {
                "label": "$101<span>K</span> - $150<span>K</span>",
                "value": 150000,
                "icon": "tal-calculator/assets/icons/income-100.svg"
            }, {
                "label": "$151<span>K</span> - $200<span>K</span>",
                "value": 200000,
                "icon": "tal-calculator/assets/icons/income-200.svg"
            }],
            "customAnswer": {
                "label": "Over $200<span>K</span>",
                "min": 200000,
                "placeholder": "e.g. $250,000",
                "icon": "tal-calculator/assets/icons/income-250.svg"
            }
        }, {
            "title": "Are you a parent?",
            "key": "hasChildren",
            "skip": {
                "test": "==1",
                "pass": ["childAmount"]
            },
            "answers": [{
                "label": "Yes",
                "value": 1,
                "icon": "tal-calculator/assets/icons/parent-yes.svg"
            }, {
                "label": "No",
                "value": 0,
                "icon": "tal-calculator/assets/icons/parent-no.svg"
            }]
        }, {
            "title": "How many children do you have?",
            "key": "childAmount",
            "skip": {
                "test": "==1",
                "pass": ["childAge"],
                "fail": ["childYoungest", "childEldest"]
            },
            "answers": [{
                "label": "1",
                "value": 1,
                "icon": "tal-calculator/assets/icons/children-one.svg"
            }, {
                "label": "2",
                "value": 2,
                "icon": "tal-calculator/assets/icons/children-two.svg"
            }, {
                "label": "3",
                "value": 3,
                "icon": "tal-calculator/assets/icons/children-three.svg"
            }, {
                "label": "4",
                "value": 4,
                "icon": "tal-calculator/assets/icons/children-four.svg"
            }, {
                "label": "5",
                "value": 5,
                "icon": "tal-calculator/assets/icons/children-five.svg"
            }]
        }, {
            "title": "How old is your youngest child?",
            "key": "childYoungest",
            "answers": [{
                "label": "0-2",
                "value": 0,
                "icon": "tal-calculator/assets/icons/child-age-0-2.svg"
            }, {
                "label": "3-9",
                "value": 3,
                "icon": "tal-calculator/assets/icons/child-age-3-9.svg"
            }, {
                "label": "10-13",
                "value": 10,
                "icon": "tal-calculator/assets/icons/child-age-10-13.svg"
            }, {
                "label": "14-17",
                "value": 14,
                "icon": "tal-calculator/assets/icons/child-age-14-17.svg"
            }, {
                "label": "18+",
                "value": 18,
                "icon": "tal-calculator/assets/icons/child-age-18.svg"
            }]
        }, {
            "title": "How old is your eldest child?",
            "key": "childEldest",
            "answers": [{
                "label": "0-2",
                "value": 2,
                "icon": "tal-calculator/assets/icons/child-age-0-2.svg"
            }, {
                "label": "3-9",
                "value": 9,
                "icon": "tal-calculator/assets/icons/child-age-3-9.svg"
            }, {
                "label": "10-13",
                "value": 13,
                "icon": "tal-calculator/assets/icons/child-age-10-13.svg"
            }, {
                "label": "14-17",
                "value": 17,
                "icon": "tal-calculator/assets/icons/child-age-14-17.svg"
            }, {
                "label": "18+",
                "value": 20,
                "icon": "tal-calculator/assets/icons/child-age-18.svg"
            }],
            "validation": {
                test: ">= childYoungest",
                message: "Please select an age older than your youngest child."
            }
        }, {
            "title": "How old is your child?",
            "key": "childAge",
            "answers": [{
                "label": "0-2",
                "value": 2,
                "icon": "tal-calculator/assets/icons/child-age-0-2.svg"
            }, {
                "label": "3-9",
                "value": 9,
                "icon": "tal-calculator/assets/icons/child-age-3-9.svg"
            }, {
                "label": "10-13",
                "value": 13,
                "icon": "tal-calculator/assets/icons/child-age-10-13.svg"
            }, {
                "label": "14-17",
                "value": 17,
                "icon": "tal-calculator/assets/icons/child-age-14-17.svg"
            }, {
                "label": "18+",
                "value": 20,
                "icon": "tal-calculator/assets/icons/child-age-18.svg"
            }]
        }, {
            "title": "Are you paying a mortgage?",
            "key": "hasMortgage",
            "skip": {
                "test": "==1",
                "pass": ["mortgageAmount"]
            },
            "answers": [{
                "label": "Yes",
                "value": 1,
                "icon": "tal-calculator/assets/icons/mortgage-yes.svg"
            }, {
                "label": "No",
                "value": 0,
                "icon": "tal-calculator/assets/icons/mortgage-no.svg"
            }]
        }, {
            "title": "How much is your mortgage?",
            "helptext": "If you have more than one mortgage, use your combined mortgage amount.",
            "key": "mortgageAmount",
            "answers": [{
                "label": "Under $250<span>K</span>",
                "value": 250000,
                "icon": "tal-calculator/assets/icons/income-40.svg"
            }, {
                "label": "$250<span>K</span> - $500<span>K</span>",
                "value": 500000,
                "icon": "tal-calculator/assets/icons/income-80.svg"
            }, {
                "label": "$501<span>K</span> - $750<span>K</span>",
                "value": 750000,
                "icon": "tal-calculator/assets/icons/income-100.svg"
            }],
            "customAnswer": {
                "label": "Over $750<span>K</span>",
                "min": 750000,
                "placeholder": "e.g. $800,000",
                "icon": "tal-calculator/assets/icons/income-200.svg"
            }
        }, {
            "title": "Do you have any other debts?",
            "helptext": "Credit cards, car or personal loans etc.",
            "key": "hasDebt",
            "skip": {
                "test": "==1",
                "pass": ["debtAmount"]
            },
            "answers": [{
                "label": "Yes",
                "value": 1,
                "icon": "tal-calculator/assets/icons/mortgage-yes.svg"
            }, {
                "label": "No",
                "value": 0,
                "icon": "tal-calculator/assets/icons/mortgage-no.svg"
            }]
        }, {
            "title": "How much debt do you have?",
            "key": "debtAmount",
            "answers": [{
                "label": "Under $5<span>K</span>",
                "value": 5000,
                "icon": "tal-calculator/assets/icons/income-60.svg"
            }, {
                "label": "$5<span>K</span> - $10<span>K</span>",
                "value": 10000,
                "icon": "tal-calculator/assets/icons/income-80.svg"
            }, {
                "label": "$11<span>K</span> - $15<span>K</span>",
                "value": 15000,
                "icon": "tal-calculator/assets/icons/income-100.svg"
            }, {
                "label": "$16<span>K</span> - $20<span>K</span>",
                "value": 20000,
                "icon": "tal-calculator/assets/icons/income-200.svg"
            }],
            "customAnswer": {
                "label": "Over $20<span>K</span>",
                "min": 20000,
                "placeholder": "e.g. $25,000",
                "icon": "tal-calculator/assets/icons/income-250.svg"
            }
        }]
    };
});