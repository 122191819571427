define('tal-calculator/validators/default', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        incomeAmount: (0, _validators.validateNumber)({
            integer: true,
            positive: true
        }),
        hasChildren: (0, _validators.validateNumber)({
            integer: true,
            gte: 0,
            lte: 1
        }),
        childAmount: (0, _validators.validateNumber)({
            integer: true,
            positive: true
        }),
        // No age limit validation needed as lookup table return 0 after age 19
        childYoungest: (0, _validators.validateNumber)({
            integer: true,
            positive: true
        }),
        childEldest: (0, _validators.validateNumber)({
            integer: true,
            positive: true
        }),
        childAge: (0, _validators.validateNumber)({
            integer: true,
            positive: true
        }),
        hasMortgage: (0, _validators.validateNumber)({
            integer: true,
            gte: 0,
            lte: 1
        }),
        mortgageAmount: (0, _validators.validateNumber)({
            integer: true,
            positive: true
        }),
        hasDebt: (0, _validators.validateNumber)({
            integer: true,
            gte: 0,
            lte: 1
        }),
        debtAmount: (0, _validators.validateNumber)({
            integer: true,
            positive: true
        })
    };
});