define('tal-calculator/transitions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function () {
        // Validation error
        this.transition(this.hasClass('tc-liquid-top'), this.use('fade', DURATION));

        // Between welcome and questions
        this.transition(this.hasClass('tc-liquid-main'), this.fromRoute('tal-calc-calculator.index'), this.use('crossFade', DURATION), this.reverse('crossFade', DURATION));

        // Between questions
        this.transition(this.hasClass('tc-liquid-questions'), this.toValue(function (toValue, fromValue) {
            return toValue && fromValue && parseInt(toValue.get('id')) > parseInt(fromValue.get('id'));
        }), this.use('crossFade', DURATION), this.reverse('crossFade', DURATION));

        // Between questions and results
        this.transition(this.hasClass('tc-liquid-main'), this.toRoute('tal-calc-results'), this.use('crossFade', DURATION), this.reverse('crossFade', DURATION));
    };

    var DURATION = { duration: 250 };
});