define('tal-calculator/utils/median', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.median = median;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function median(values) {
    if (!Array.isArray(values)) {
      throw new TypeError('You need to pass an Array not ' + (typeof values === 'undefined' ? 'undefined' : _typeof(values)));
    }

    if (values.length == 1) {
      return values[0];
    }

    values.sort(function sortValues(a, b) {
      return a - b;
    });

    var half = Math.floor(values.length / 2);

    if (values.length % 2) return values[half];else return (values[half - 1] + values[half]) / 2.0;
  }
});