define('tal-calculator/components/tal-calc-question', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        progress: Ember.inject.service('tal-calc-progress'),
        question: null,
        classNameBindings: ['isAnswered'],
        classNames: ['tc-question'],

        // Get the current answer of the question from state service.
        // If wantsCustomInput has been toggled, return null to clear
        // previously selected answer.
        currentAnswer: Ember.computed('question', 'wantsCustomInput', function () {
            if (this.get('wantsCustomInput')) return null;
            var key = this.get('question.key');
            return this.get('progress').getValue(key);
        }),

        // Class binding to determine if question currently has a valid answer
        isAnswered: Ember.computed('currentAnswer', function () {
            return Number.isInteger(this.get('currentAnswer'));
        }),

        // Return highest possible answer of given options (not custom input)
        highestAnswer: Ember.computed('question', function () {
            // Easier with lodash if I can work out how to import it
            return this.get('question.answers').map(function (answer) {
                return answer.value;
            }).reduce(function (highest, current) {
                return highest > current ? highest : current;
            }, 0);
        }),

        // Determine whether the custom input should show a value when shown.
        // If an answer higher than the given options was given (via previous custom input)
        // then show it, otherwise show input placeholder.
        initialCustomValue: Ember.computed('currentAnswer', 'highestAnswer', function () {
            if (this.get('currentAnswer') <= this.get('highestAnswer')) {
                return null;
            } else {
                return this.get('currentAnswer');
            }
        }),

        // Check whether this question allows for a custom input from original json
        allowCustomInput: Ember.computed('question', 'currentAnswer', function () {
            return this.get('question.customAnswer') ? true : false;
        }),

        // Does the user currently want to see the custom input as a result of
        // pressing the appropriate answer.
        wantsCustomInput: false,

        // Should the custom input be shown. Depends on whether the question allows it,
        // if the current answer was input via the input or ir the user requested it.
        showCustomInput: Ember.computed('highestAnswer', 'currentAnswer', 'allowCustomInput', 'wantsCustomInput', function () {
            // If allowed in data.json
            if (!this.get('allowCustomInput')) return false;
            // If currentAnswer is higher than all possible set answers
            if (this.get('currentAnswer') > this.get('highestAnswer')) return true;
            // If has been toggled
            return this.get('wantsCustomInput');
        }),

        // Reset wantsCustomInput when a new question is loaded
        refesh: Ember.observer('question', function () {
            this.set('wantsCustomInput', false);
        }),

        actions: {
            toggleCustomInput: function toggleCustomInput() {
                var _this = this;

                this.set('wantsCustomInput', true);

                Ember.run.next(function () {
                    _this.$('.tc-question__custominput input').focus();
                });
            }
        }
    });
});