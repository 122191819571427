define('ember-config-service/services/config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    __config__: Ember.computed(function () {
      return Ember.getOwner(this).factoryFor('config:environment').class;
    }),

    unknownProperty: function unknownProperty(path) {
      return Ember.get(this, '__config__.' + path);
    }
  });
});