define('tal-calculator/routes/tal-calc-calculator', ['exports', 'ember-changeset-validations', 'ember-changeset', 'tal-calculator/data/default', 'tal-calculator/validators/default'], function (exports, _emberChangesetValidations, _emberChangeset, _default, _default2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        router: Ember.inject.service('-routing'),
        progress: Ember.inject.service('tal-calc-progress'),
        config: Ember.inject.service('tal-calc-config'),
        initialValues: Ember.Object.create(),

        // Populate store with questions and config from given JSON
        init: function init(data) {
            var _this = this;

            var questionData = data.hasOwnProperty("questions") ? data.questions : _default.default.questions;

            questionData.forEach(function (question, index) {
                _this.store.createRecord('tal-calc-question', {
                    id: index + 1,
                    title: question.title,
                    helptext: question.helptext,
                    key: question.key,
                    skip: question.skip || null,
                    answers: question.answers || [],
                    customAnswer: question.customAnswer || null,
                    validation: question.validation || null
                });
            });

            var configData = data ? Ember.assign({}, _default.default.config, data.config) : _default.default.config;
            this.get('config').setSettings(configData);
        },


        // Fetches initial answers from query params. Data is loaded here from
        // instance app.
        beforeModel: function beforeModel(params) {
            var validations = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var merge = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

            this.get('config').setParams(params.queryParams);
            this.triggerValidations(validations, merge);
        },


        // This is only needed for the debug component
        model: function model() {
            return this.store.peekAll('tal-calc-question');
        },


        // Clear any current progress in the service and create
        // a new default entry for each question in the store
        initialiseState: function initialiseState() {
            var _this2 = this;

            var progress = this.get('progress');
            var questions = this.store.peekAll('tal-calc-question');
            progress.clearAll();

            questions.forEach(function (question) {
                // Populate any initial values from query params into progress state
                var value = parseInt(Ember.get(_this2.get('initialValues'), question.get('key')));
                progress.addQuestion(question, value);
            });

            this.questionVisibility();
        },


        // For each question in the store, determine whether it
        // should be visible as a result of another (parent) questions
        // answer. Visibility to saved in the progress service.
        questionVisibility: function questionVisibility() {
            var _this3 = this;

            var progress = this.get('progress');
            var questions = this.store.peekAll('tal-calc-question');

            questions.forEach(function (question) {
                var key = question.get('key');

                // Regardless of parent value, set visibility to
                // false as the value was loaded via a query param.
                if (progress.getLoaded(key)) {
                    progress.setVisible(key, false);
                }

                // Return if no skip object
                if (!question.get('skip')) return;

                var currentAnswer = progress.getValue(key);
                var test = question.get('skip.test');

                var passArray = question.get('skip.pass') || [];
                var failArray = question.get('skip.fail') || [];

                _this3.testSkip(passArray, currentAnswer, test, true);
                _this3.testSkip(failArray, currentAnswer, test, false);
            });
        },


        // Takes a pass or fail array and peforms the required test
        // to determine whether each question should be hidden or
        // or shown based on its parent question's answer. If a question
        // is to be hidden its value is reset to null to help calculate
        // its children's visibility easier.
        // @param {array} array - the array of child questions to loop
        // @param {int} answer - the current answer of the parent question
        // @param {string} test - the operation to perform on the answer
        // @param {bool} shouldPass - should the test pass or fail make the child question visible
        testSkip: function testSkip(array, answer, test, shouldPass) {
            var progress = this.get('progress');

            array.forEach(function (question) {
                // Not very DRY but needs to happen in this order.
                // If answer is null or eval return opposite of desired,
                // the question is reset, including its loaded value to
                // give the effect of the query param being ignore if
                // gparent questions are not set via query params as well.
                if (answer === null) {
                    progress.setVisible(question, false);
                    progress.setValue(question, null);
                    progress.setLoaded(question, false);
                } else if (eval(answer + ' ' + test) == shouldPass) {
                    progress.setVisible(question, true);
                } else {
                    progress.setVisible(question, false);
                    progress.setValue(question, null);
                    progress.setLoaded(question, false);
                }
            });
        },

        triggerValidations: function triggerValidations(newValidations, merge) {
            // Return merged array (even if newValidations is empty) or
            // just newValidations depending on merge.
            var validations = merge ? Ember.merge(_default2.default, newValidations) : newValidations;

            // Wrap initialValues (empty array on init) in a changeset
            // and pass in validations. As each query param is added to the
            // changeset, the appropriate validation is fired.
            var changeset = new _emberChangeset.default(this.get('initialValues'), (0, _emberChangesetValidations.default)(validations), validations);
            var params = this.get('config').getParams();
            for (var key in params) {
                changeset.set(key, params[key]);
            }

            // Apply valid values in changeset directy to initialValues.
            // changseet.execute() did not work as per docs.
            this.get('initialValues').setProperties(changeset.get('change'));

            // As triggerValidations is only run on beforeModel, we can assume
            // a clean progress service is needed.
            this.initialiseState();

            // Show error alert if some query params have validation errors
            if (Object.keys(changeset.get('error')).length) {
                this.get('progress').pushMessage('Some imported values were invalid. These will be ignored.');
            }
        },
        transitionToQuestion: function transitionToQuestion(questionID) {
            // Fetch question to navigate to
            var question = this.store.peekRecord('tal-calc-question', questionID);

            if (question) {
                // if question exists, fetch its visibility status
                var questionKey = question.get('key');

                if (this.get('progress').getVisible(questionKey)) {
                    // If visible transition to it
                    this.transitionTo('tal-calc-question', questionID);
                    return true;
                } else {
                    // If exists but not visible return 1
                    return 1;
                }
            } else {
                // If question doesnt exist (start or end of questions) return 2
                return 2;
            }
        },


        // Output result to a global object
        resultsToWindow: function resultsToWindow(life, tpd, trauma) {
            window.talCalculator = { life: life, tpd: tpd, trauma: trauma };
            // console.info(window.talCalculator);
        },


        maintainNiceLabels: Ember.observer('progress.state.@each.value', function () {
            var _this4 = this;

            Ember.run.later(function () {
                // Merge progress arrays for GTM data
                var labelArray = _this4.get('progress.state').map(function (answer) {
                    var matchedItem = _this4.get('progress.labels').findBy('key', answer.key);

                    if (!matchedItem.value && answer.value) {
                        return {
                            key: answer.key,
                            value: 'Param: $' + answer.value
                        };
                    } else {
                        return matchedItem;
                    }
                });
                _this4.set('progress.niceLabels', labelArray);
            });
        }),

        actions: {
            questionVisibility: function questionVisibility() {
                this.questionVisibility();
            },
            resetCalculator: function resetCalculator() {
                this.initialiseState();
                this.transitionTo('tal-calc-calculator.index');
            },
            nextQuestion: function nextQuestion(questionID) {
                // Take current question model to determine next question ID
                var nextQuestionID = parseInt(questionID) + 1;

                switch (this.transitionToQuestion(nextQuestionID)) {
                    case 1:
                        // Try the next question in the sequence
                        this.send('nextQuestion', nextQuestionID);
                        break;

                    case 2:
                        // End of questions fire finishQuiz action
                        this.send('finishQuiz');
                        break;
                }
            },
            prevQuestion: function prevQuestion(questionID) {
                // Take current question model to determine previous question ID
                var prevQuestionID = parseInt(questionID) - 1;

                switch (this.transitionToQuestion(prevQuestionID)) {
                    case 1:
                        // Try the prev question in the sequence
                        this.send('prevQuestion', prevQuestionID);
                        break;

                    case 2:
                        // Start of questions so goto splash page
                        this.transitionTo('tal-calc-calculator.index');
                        break;
                }
            },
            prevQuestionViaHeader: function prevQuestionViaHeader() {
                // If current route is not a question, back button will restart
                var currentRouteName = this.get('router.currentRouteName');
                if (currentRouteName === 'tal-calc-question') {
                    // As prevQuestionViaHeader is called from the header (this route) we cannot
                    // pass the current question model as a param. So we have to find it first.
                    var questionID = this.modelFor('tal-calc-question').get('id');
                    this.send('prevQuestion', questionID);
                } else {
                    var lastQuestionID = this.currentModel.get('length');
                    // +1 to account for the -1 in prevQuestion
                    this.send('prevQuestion', lastQuestionID + 1);
                }
            },


            // Transition to the appropriate page after all questions have been answered.
            // Defaults to results page
            finishQuiz: function finishQuiz() {
                this.transitionTo('tal-calc-results');
            },


            // This action should be overwritten to customise output handling per instance
            outputResults: function outputResults(life, tpd, trauma) {
                this.resultsToWindow(life, tpd, trauma);
            }
        }
    });
});