define('tal-calculator/routes/tal-calc-question', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        progress: Ember.inject.service('tal-calc-progress'),

        model: function model(params) {
            return this.store.peekRecord('tal-calc-question', params.id);
        },
        beforeModel: function beforeModel() {
            // Catch any attempt to enter app after first question
            var income = this.get('progress').getValue('incomeAmount');
            if (!income) {
                this.transitionTo('tal-calc-question', 1);
            }
        },
        afterModel: function afterModel(model) {
            document.title = model.get('title');
        },


        actions: {
            answerQuestion: function answerQuestion(answer) {
                var answerObject = typeof answer === 'number' ? { label: 'Custom: $' + answer, value: answer } : answer;

                // If a validation object exists for this question in the data file,
                // Confirm it passes the test before saving. If not push to alert component.
                var validation = this.currentModel.get('validation');
                if (validation) {
                    var operator = validation.test.split(' ')[0];
                    var comparison = validation.test.split(' ')[1];
                    var comparisonValue = this.get('progress').getValue(comparison);
                    // Eval means less manipulation of the users input.
                    if (!eval(answerObject.value + ' ' + operator + ' ' + comparisonValue)) {
                        this.get('progress').pushMessage(validation.message);
                        return false;
                    }
                }

                var key = this.currentModel.get('key');

                // Set answer and label in progress service
                this.get('progress').setValue(key, answerObject);
                // // Refresh question visibilities
                this.send('questionVisibility');
                // // Navigate to next available question
                this.send('nextQuestion', this.currentModel.get('id'));
            }
        }
    });
});