define('tal-calculator/components/tal-calc-answer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        parent: Ember.inject.service('config'),
        tagName: 'li',
        classNames: ['tc-answer'],
        classNameBindings: ['isCurrent'],
        answer: null,
        current: null,
        isCurrent: Ember.computed('answer', 'current', function () {
            // Custom input option recieves a boolean as its current value
            if (Ember.typeOf(this.get('current')) === 'boolean') return this.get('current');
            return this.get('answer.value') === this.get('current');
        })
    });
});