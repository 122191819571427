define('tal-calculator/services/tal-calc-config', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        settings: {},
        params: {},

        setSettings: function setSettings(input) {
            this.set('settings', input);
        },
        setParams: function setParams(input) {
            this.set('params', input);
        },
        getParams: function getParams() {
            return this.get('params');
        }
    });
});