define('tal-calculator/routes/tal-calc-results', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        progress: Ember.inject.service('tal-calc-progress'),

        beforeModel: function beforeModel() {
            // Catch any attempt to enter app after first question
            var income = this.get('progress').getValue('incomeAmount');
            if (!income) {
                this.transitionTo('tal-calc-question', 1);
            }
        },
        afterModel: function afterModel() {
            document.title = 'Your suggested level of cover';
        }
    });
});