define('tal-calculator/services/tal-calc-progress', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        state: Ember.A(),
        alertMessage: null,

        // Save labels of answers as they are answered
        labels: Ember.A(),

        // Nicely formatted final answers/labels for GTM
        niceLabels: Ember.A(),

        // If a value is given via a query param, set loaded to true
        // to force hide of question.
        addQuestion: function addQuestion(question, value) {
            this.get('labels').pushObject({
                key: question.get('key'),
                value: null
            });

            this.get('state').pushObject({
                key: question.get('key'),
                visible: true,
                value: value,
                loaded: !isNaN(value)
            });
        },
        getQuestion: function getQuestion(key) {
            return this.get('state').findBy('key', key);
        },
        getVisible: function getVisible(key) {
            var question = this.getQuestion(key);
            return Ember.get(question, 'visible');
        },
        setVisible: function setVisible(key, value) {
            var question = this.getQuestion(key);
            Ember.set(question, 'visible', value);
        },
        getValue: function getValue(key) {
            var question = this.getQuestion(key);
            return Ember.get(question, 'value');
        },
        setValue: function setValue(key, answer) {
            var question = this.getQuestion(key);
            var labelQuestion = this.get('labels').findBy('key', key);

            if (answer) {
                Ember.set(question, 'value', answer.value);
                Ember.set(labelQuestion, 'value', answer.label.replace(/(<([^>]+)>)/ig, ""));
            } else {
                Ember.set(question, 'value', null);
                Ember.set(labelQuestion, 'value', null);
            }
        },
        getLoaded: function getLoaded(key) {
            var question = this.getQuestion(key);
            return Ember.get(question, 'loaded');
        },
        setLoaded: function setLoaded(key, value) {
            var question = this.getQuestion(key);
            return Ember.set(question, 'loaded', value);
        },
        addLabel: function addLabel(key, value) {
            this.get('labels').pushObject({ key: key, value: value });
        },
        pushMessage: function pushMessage(message) {
            this.set('alertMessage', message);
        },
        clearAll: function clearAll() {
            this.set('state', Ember.A());
            this.set('labels', Ember.A());
            this.set('niceLabels', Ember.A());
        }
    });
});