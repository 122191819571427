define('tal-calculator/router', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function (router, path) {
        router.route('tal-calc-calculator', { path: path, resetNamespace: true }, function () {
            this.route('tal-calc-question', { path: ':id', resetNamespace: true });
            this.route('tal-calc-results', { path: 'results', resetNamespace: true });
        });
    };
});