define('tal-calculator/components/tal-calc-custom-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['tc-question__custominput'],
        inputValue: null,
        outputValue: null,
        minValue: null,

        validationFailed: Ember.computed('outputValue', 'minValue', function () {
            // Small custom validation to disable submit button if custom value
            // is not greater that the highest answer.
            if (!this.get('outputValue')) return true;
            return this.get('outputValue') <= this.get('minValue');
        })
    });
});