define('tal-calculator/controllers/tal-calc-calculator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        router: Ember.inject.service('-routing'),
        progress: Ember.inject.service('tal-calc-progress'),
        parent: Ember.inject.service('config'),

        hideBackBtn: Ember.computed('router.currentRouteName', function () {
            return this.get('router.currentRouteName') === 'tal-calc-calculator.index' ? true : false;
        })
    });
});