define('tal-calculator/controllers/tal-calc-results', ['exports', 'numeral'], function (exports, _numeral) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        calculations: Ember.inject.service('tal-calc-calculations'),
        config: Ember.inject.service('tal-calc-config'),
        settings: Ember.computed.alias('config.settings'),

        reachedCoverageLimit: Ember.computed('calculations.life', 'settings.coverageLimit', function () {
            return this.get('calculations.life') > this.get('settings.coverageLimit');
        }),

        coverageLimitFormatted: Ember.computed('settings.coverageLimit', function () {
            return (0, _numeral.default)(this.get('settings.coverageLimit')).format('0.0a');
        })
    });
});