define("tal-calculator/components/tal-calc-input-currency", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.TextField.extend({
        options: {
            prefix: "$ ",
            groupSeparator: ",",
            alias: "numeric",
            autoGroup: true,
            digits: 0,
            rightAlign: false,
            placeholder: '',
            showMaskOnHover: false,
            allowMinus: false
        },

        unmaskedValue: null,

        _initialize: Ember.on('didInsertElement', function () {
            this.$().inputmask(this.get('options'));
            this.updateMaskFromValue();
            this.addEventListeners();
            this.notifyPropertyChange('unmaskedValue');
        }),

        /**
         * @method addEventListeners
         */
        addEventListeners: function addEventListeners() {
            this.$().on('blur keyup', Ember.run.bind(this, this.updateValueFromMask));
        },


        /**
         * @method addEventListeners
         */
        removeEventListeners: function removeEventListeners() {
            this.$().off('blur keyup');
        },


        /**
         * @method updateValueFromMask
         * Trigger value update on mask change
         */
        updateValueFromMask: function updateValueFromMask() {
            if (this.get('isDestroying')) {
                return;
            }

            var inputValue = this.$().inputmask('unmaskedvalue'),
                unmaskedValue = this.get('unmaskedValue');

            if (String(inputValue) === String(unmaskedValue)) {
                return;
            }

            this.set('unmaskedValue', inputValue);
        },


        /**
         * @method updateMaskFromValue
         * Trigger mask update on value change from controller
         */
        updateMaskFromValue: function updateMaskFromValue() {

            if (this.get('isDestroying')) {
                return;
            }

            var inputValue = this.$().inputmask('unmaskedvalue'),
                unmaskedValue = this.get('unmaskedValue');

            if (inputValue === unmaskedValue) {
                return;
            }

            this.$().val(unmaskedValue);
        },


        /**
         * @method contentDidChange
         * Trigger value update once on each run loop
         */
        contentDidChange: Ember.observer('unmaskedValue', function () {
            Ember.run.scheduleOnce('actions', this, this.updateMaskFromValue);
            this.get('onchange')(parseInt(this.get('unmaskedValue')));
        }),

        /**
        * @method _teardown
        * Destroy jQuery plugin
        */
        _teardown: Ember.on('willDestroyElement', function () {
            this.removeEventListeners();
            this.$().inputmask('remove');
        })
    });
});