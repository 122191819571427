define('tal-calculator/routes/tal-calc-calculator/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        afterModel: function afterModel() {
            document.title = 'What level of cover do I need?';
        }
    });
});