define('tal-calculator/models/tal-calc-question', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr('string'),
        helptext: _emberData.default.attr('string'),
        key: _emberData.default.attr('string'),
        answers: _emberData.default.attr(),
        skip: _emberData.default.attr(),
        customAnswer: _emberData.default.attr(),
        validation: _emberData.default.attr(),

        // Debug
        progress: Ember.inject.service('tal-calc-progress'),
        row: Ember.computed('progress.state.@each.value', function () {
            return JSON.stringify(this.get('progress').getQuestion(this.get('key')));
        })
    });
});