define('tal-calculator/helpers/tal-calc-currency', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.formatCurrency = formatCurrency;
    function formatCurrency(value) {
        return '$' + parseInt(value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    exports.default = Ember.Helper.helper(formatCurrency);
});