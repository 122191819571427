define('tal-calculator/services/tal-calc-calculations', ['exports', 'tal-calculator/utils/median'], function (exports, _median) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        progress: Ember.inject.service('tal-calc-progress'),

        // Income calculations
        years: 3,
        percentage: 50,
        income: Ember.computed('progress.state.@each', 'progress.state.@each.value', function () {
            var income = this.get('progress').getValue('incomeAmount');
            return Math.round(income * this.get('years') * this.get('percentage') / 100) || 0;
        }),

        // Mortage calculations
        mortgage: Ember.computed('progress.state.@each', 'progress.state.@each.value', function () {
            var progress = this.get('progress');
            var hasMortgage = progress.getValue('hasMortgage');
            return hasMortgage ? progress.getValue('mortgageAmount') : 0;
        }),

        // Debt calculations
        debt: Ember.computed('progress.state.@each', 'progress.state.@each.value', function () {
            var progress = this.get('progress');
            var hasDebt = progress.getValue('hasDebt');
            return hasDebt ? progress.getValue('debtAmount') : 0;
        }),

        // Family calculations
        ageLookup: [315822, 302856, 289830, 276804, 263778, 249426, 235074, 220722, 201846, 182970, 164094, 145218, 126342, 107466, 88590, 69714, 50838, 31962, 13086, 13086],
        getAgeLookup: function getAgeLookup(age) {
            // If age is above 19 or null, return 0;
            return age === null || age > 19 ? 0 : this.get('ageLookup')[age];
        },

        family: Ember.computed('progress.state.@each', 'progress.state.@each.value', function () {
            // Fetch all family related values from progress service
            var progress = this.get('progress');
            var hasChildren = progress.getValue('hasChildren');
            var childAmount = progress.getValue('childAmount');
            var childYoungest = progress.getValue('childYoungest');
            var childEldest = progress.getValue('childEldest');
            var childAge = progress.getValue('childAge');

            // Find median of child lookup values and multiple by number of children.
            // Null answers (not answered by user) will return false or zero values where required.
            var lookupValue = childAmount > 1 ? (0, _median.median)([this.getAgeLookup(childYoungest), this.getAgeLookup(childEldest)]) : this.getAgeLookup(childAge);

            // if hasChildren is false return zero, otehrwise return calculation
            return hasChildren ? lookupValue * childAmount : 0;
        }),

        // Final calculations
        funeral: 15000,
        traumamin: 150000,
        traumamax: 1000000,
        collectValues: Ember.computed.collect('income', 'family', 'mortgage', 'debt', 'funeral'),
        life: Ember.computed.sum('collectValues'),
        tpd: Ember.computed.alias('life'),
        trauma: Ember.computed('life', function () {
            if (this.get('life') < this.get('traumamin')) {
                return Math.round(this.get('life'));
            } else if (this.get('life') > this.get('traumamax')) {
                return Math.round(this.get('life') * 0.15);
            } else {
                return Math.round(this.get('traumamin'));
            }
        })
    });
});