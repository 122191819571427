define('tal-calculator/components/tal-calc-alert', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        content: null,
        visible: false,

        contentChange: Ember.observer('content', function () {
            var _this = this;

            if (this.get('content')) {
                this.set('visible', true);

                // This is a bit nasty. Lets look at refactor.
                Ember.run.later(function () {
                    _this.set('visible', false);
                    Ember.run.later(function () {
                        _this.set('content', null);
                    }, 250);
                }, 3000);
            }
        }),

        didInsertElement: function didInsertElement() {
            this.notifyPropertyChange('content');
        }
    });
});