define('ember-power-select-typeahead/components/power-select-typeahead/trigger', ['exports', 'ember-power-select-typeahead/templates/components/power-select-typeahead/trigger'], function (exports, _trigger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      isBlank = Ember.isBlank,
      run = Ember.run,
      computed = Ember.computed;
  exports.default = Component.extend({
    layout: _trigger.default,
    tagName: '',

    // CPs
    text: computed('select.selected', 'extra.labelPath', {
      get: function get() {
        return this.getSelectedAsText();
      },
      set: function set(_, v) {
        return v;
      }
    }),

    // Lifecycle hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var oldSelect = this.get('oldSelect');
      var newSelect = this.set('oldSelect', this.get('select'));
      if (!oldSelect) {
        return;
      }
      /*
       * We need to update the input field with value of the selected option whenever we're closing
       * the select box. But we also close the select box when we're loading search results and when
       * we remove input text -- so protect against this
       */
      if (oldSelect.isOpen && !newSelect.isOpen && !newSelect.loading && newSelect.searchText) {
        var input = document.querySelector('#ember-power-select-typeahead-input-' + newSelect.uniqueId);
        var newText = this.getSelectedAsText();
        if (input.value !== newText) {
          input.value = newText;
        }
        this.set('text', newText);
      }

      if (newSelect.lastSearchedText !== oldSelect.lastSearchedText) {
        if (isBlank(newSelect.lastSearchedText)) {
          run.schedule('actions', null, newSelect.actions.close, null, true);
        } else {
          run.schedule('actions', null, newSelect.actions.open);
        }
      } else if (!isBlank(newSelect.lastSearchedText) && newSelect.options.length === 0 && newSelect.loading) {
        run.schedule('actions', null, newSelect.actions.close, null, true);
      } else if (oldSelect.loading && !newSelect.loading && newSelect.options.length > 0) {
        run.schedule('actions', null, newSelect.actions.open);
      }
    },


    // Actions
    actions: {
      stopPropagation: function stopPropagation(e) {
        e.stopPropagation();
      },
      handleKeydown: function handleKeydown(e) {
        if ([38, 40].indexOf(e.keyCode) > -1 && !this.get('select.isOpen')) {
          e.stopPropagation();
          return;
        }
        var isLetter = e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32; // Keys 0-9, a-z or SPACE
        if (isLetter || [13, 27].indexOf(e.keyCode) > -1) {
          e.stopPropagation();
        }

        var onkeydown = this.get('onKeydown');
        if (onkeydown && onkeydown(e) === false) {
          return false;
        }
      },
      handleInputLocal: function handleInputLocal(e) {
        this.get('onInput')(e);
        this.set('text', e.target.value);
      }
    },

    // Methods
    getSelectedAsText: function getSelectedAsText() {
      var labelPath = this.get('extra.labelPath');
      var value = void 0;
      if (labelPath) {
        value = this.get('select.selected.' + labelPath);
      } else {
        value = this.get('select.selected');
      }
      if (value === undefined) {
        value = '';
      }
      return value;
    }
  });
});